.contact-container-form {
  background-color: $primary-color;
}

.contact-form {
  background-color: white;
  width: 100%;
  height: 50vh;
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.card-contact-us {
  background-color: $primary-color;
  border-radius: 8px;
  color: #fff;
  max-width: 100%;
  // margin: 20px 0 70px;
  // min-height: 400px;
  padding: 30px;

  & label {
    margin-top: 15px;
  }

  & .card-title {
    color: #B33C12;
    text-align: center;
  }

  & .btn {
    margin-top: 30px;
  }



  .input-group-text {
    border: 1px solid $white-color;
  }
}


.info-wrap {

  h3 {
    // color: $white-color;
    // font-family: $font-family-body;
    // padding-bottom: 50px;
  }

  .dbox {
    width: 100%;
    // color: rgba(0, 0, 0, .8);
    margin-bottom: 25px;


    &:last-child() {
      margin-bottom: 0;
    }

    i {
      color: $primary-color;
    }

    p {
      margin-bottom: 0;
      font-size: 1.3em;

      span {
        font-weight: 600;
        // color: $white-color;
      }

      a {
        // color: $white-color;
        color: $black-color;
        text-decoration: none;
      }
    }



    .icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      // background: rgba(255, 255, 255, .1);
      background-color: #4e8eb15e;
      margin-right: 20px;

      span {
        font-size: 20px;
        // color: $white-color;
        color: $primary-color;
      }
    }

    .text {
      width: calc(100% - 50px);
    }
  }

  // @include media-breakpoint-up(lg) {
  //   margin-top: -20px;
  //   margin-bottom: -20px;
  //   border-radius: 5px;
  // }
}

.invalid {
  // border: 1px solid red !important;
  background-color: #ffc0a4;
  border: 1px solid #f5593d;
  color: #f5593d;
}


input,
textarea {
  outline: none !important;
  box-shadow: none !important;
}

.badge {
  background-color: $white-color;
  color:  $danger-color;
  font-weight: bold;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.badge-success {
  background-color: $success-color;
  color:  $white-color;
  font-weight: bold;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}


.error-captcha {
	color: red;
	margin-bottom: 20px;
}