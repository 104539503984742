.nav {
  .nav-item {

    .nav-link:hover,
    .nav-link:focus {
      background-color: transparent;
    }
  }
}

.navbar {
  border: $none;
  font-size: $font-size-small;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  padding: 0;
  background: $white-color;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .navbar-brand {
    font-weight: $font-weight-bold;
    margin: $navbar-margin-brand;
    padding: $navbar-padding-brand;
    font-size: $font-size-small;
    color: $default-color;
    text-transform: uppercase;
  }

  .navbar-toggler {
    outline: none;
    border: 1px solid transparent;
  }

  .navbar-toggler:focus {
    outline: none;
    border: 1px solid transparent;
  }

  .form-control-feedback {
    padding-left: 0px;
  }

  &:not([class*="bg"]) {
    .navbar-toggler {
      .navbar-toggler-bar {
        // background: $default-color !important;
        background-color: #346f8f !important;
      }
    }
  }

  .navbar-nav {
    .nav-item .nav-link {
      line-height: 1.6;
      // margin: $navbar-margin-a;
      margin: 0 20px 0 20px;
      padding: $navbar-padding-a;
      opacity: .8;
      font-size: $font-size-base;
      text-transform: uppercase;
      font-weight: 600;
      color: $default-color;

      p {
        margin: 0px 0px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.5em;
        padding: 15px 0;
      }
    }

    .nav-item .nav-link.btn {
      margin: $navbar-margin-a-btn;
      padding: 9px;

    }

    .nav-item .nav-link [class^="fa"] {
      font-size: 17px;
      position: relative;
      right: 5px;
    }

    .dropdown-menu {
      border-radius: $border-radius-extreme;
      margin-top: 1px;
    }

    .nav-item {
      .btn {
        i {
          color: $white-color;
        }
      }
    }
  }

  .navbar-nav2 {
    .nav-item .nav-link {
      line-height: 1.6;
      // margin: $navbar-margin-a;
      margin: 0 20px 0 20px;
      padding: $navbar-padding-a;
      opacity: .8;
      font-size: $font-size-base;
      text-transform: uppercase;
      font-weight: 600;
      // color: $default-color;
      color: #e7e7e7;

      p {
        margin: 0px 0px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.5em;
        padding: 15px 0;
      }
    }

    .nav-link:hover,
    .active .nav-link:focus,
    .nav-item .nav-link:hover,
    .nav-item .nav-link:focus {
      background-color: transparent;
      color: $white-color;
      @include opacity(1);
    }

    .nav-item .nav-link.btn {
      margin: $navbar-margin-a-btn;
      padding: 9px;

    }

    .nav-item .nav-link [class^="fa"] {
      font-size: 17px;
      position: relative;
      right: 5px;
    }

    .dropdown-menu {
      border-radius: $border-radius-extreme;
      margin-top: 1px;
    }

    .nav-item {
      .btn {
        i {
          color: $white-color;
        }
      }
    }
  }


  .navbar-collapse {
    & .nav-item {
      & .nav-link {
        p {
          display: inline;
        }
      }

      & .dropdown-item {
        i {
          margin: 0 10px;
          margin: 0 10px 0px 5px;
          font-size: 18px;
          position: relative;
          top: 3px;
        }
      }
    }

    &.show {
      & .navbar-nav {
        & .nav-item {
          padding-right: 10px;
        }
      }
    }

    &:after {
      background-color: white;
      // background-color: rgba(78, 142, 177);
    }
  }

  #navbarSupportedContent {
    .nav-item {
      position: relative;
    }
  }

  .notification-bubble {
    padding: 0.4em 0.6em;
    position: absolute;
    top: 10px;
    right: -2px;
  }

  .btn {
    margin: 14px 3px;
    font-size: $font-size-small;

    i {
      font-size: 14px;
      position: relative;
      top: 2px;
    }
  }

  .btn-simple {
    font-size: $font-size-medium;
  }

  .caret {
    @include center-item();
  }

  &.navbar-transparent {
    background: transparent !important;
    padding-top: 25px;
    box-shadow: none;

    .navbar-brand {
      color: $white-color;
    }

    .navbar-nav {
      .nav-item .nav-link {
        color: $white-color;
      }
    }

    .navbar-toggler {
      .navbar-toggler-bar {
        background: $white-color !important;
        // background-color: #346f8f !important;
      }
    }
  }

  &.navbar-transparent2 {
    background: transparent !important;
    padding-top: 25px;
    box-shadow: none;

    .navbar-brand {
      color: $white-color;
    }

    .navbar-nav {
      .nav-item .nav-link {
        // color: $white-color;
        color: #346f8f;
      }
    }

    .navbar-toggler {
      .navbar-toggler-bar {
        // background: $white-color !important;
        background-color: #346f8f !important;
      }
    }
  }

  .logo-container {
    margin-top: 5px;

    .logo {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #333333;
      width: 50px;
      float: left;

      img {
        width: 100%;
      }
    }

    .brand {
      font-size: 18px;
      color: #FFFFFF;
      line-height: 20px;
      float: left;
      margin-left: 10px;
      margin-top: 5px;
      width: 75px;
      // height: 50px;
    }
  }

  .navbar-toggler .navbar-toggler-bar+.navbar-toggler-bar,
  .navbar-toggler .navbar-toggler-icon+.navbar-toggler-icon {
    margin-top: 4px;
  }

  .navbar-toggler {

    border: 1px solid transparent;

    .navbar-toggler-bar {
      background: $white-color !important;
      display: block;
      position: relative;
      width: 24px;
      height: 2px;
      border-radius: 1px;
      margin: 0 auto;
    }
  }
}

.navbar-transparent,
[class*="bg"] {
  .navbar-brand {
    color: $white-color;
    @include opacity(.9);

    &:focus,
    &:hover {
      background-color: transparent;
      @include opacity(1);
      color: $white-color;
    }
  }

  .navbar-nav {
    .nav-item .nav-link:not(.btn) {
      color: $white-color;
      border-color: $white-color;
    }

    .active .nav-link .active .nav-link:hover,
    .active .nav-link:focus,
    .nav-item .nav-link:hover,
    .nav-item .nav-link:focus {
      background-color: transparent;
      color: $white-color;
      @include opacity(1);
    }

    .nav .nav-item a.btn:hover {
      background-color: transparent;
    }

    .dropdown .nav-link .caret,
    .dropdown .nav-link:hover .caret,
    .dropdown .nav-link:focus .caret {
      border-bottom-color: $white-color;
      border-top-color: $white-color;
    }

    .open .nav-link,
    .open .nav-link:hover,
    .open .nav-link:focus {
      background-color: transparent;
      color: $default-color;
      @include opacity(1);
    }
  }

  .btn-default.btn-fill {
    color: $dark-gray;
    background-color: $white-color;
    @include opacity(.9);
  }

  .btn-default.btn-fill:hover,
  .btn-default.btn-fill:focus,
  .btn-default.btn-fill:active,
  .btn-default.btn-fill.active,
  .open .dropdown-toggle.btn-fill.btn-default {
    border-color: $white-color;
    @include opacity(1);
  }

}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.bd-docs {
  .navigation-example {
    .navbar.navbar-transparent {
      padding-top: 0;
      padding: 20px 0;
      margin-top: 20px;
    }
  }
}

.fixed-top-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-top-site-above-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.site-above-header {
  background-color: #fff;
  padding: 10px 0px 0px 0px;
  border-width: 0 0 1px 0;
  border-color: rgba(255, 255, 255, 0.425);
  border-style: solid;
}

.img-logo {
  width: 100%;
  transform: translate(0, 25px);
  max-width: 60px; //110
  height: auto;
}

.img-brand {
  width: 35%;
  max-width: 250px;
  height: "auto";
}

.new-navbar {
  height: 300px;
}

.new-navbar2 {
  height: 300px;
  background-color: $primary-color;
}


.title-page-header {
  margin-bottom: 50px;
}

.title-page-header p {
  font-size: 0.825em;
  color: #c0c1c2;
}

.presentation-subtitle {
  font-size: 2.8em; //1.7em; 2.8em
  color: #ffffff;
}

.img-valores {
  width: 50%;
  height: auto;
}

.img-abogados {
  width: 100%;
  height: auto;
}

@media (max-width: 992px) {

  .site-above-header {
    display: none;
  }

  .new-navbar {
    top: -10px;
    min-height: 125px;
    max-height: 125px;
  }

  .new-navbar2 {
    top: -10px;
    min-height: 125px;
    max-height: 125px;
    background-color: $white-color;
  }

  .fill img {
    max-width: 100%;
    height: auto;
  }

  .title-page-header {
    margin-bottom: 0px;
  }

}

@media (min-width: 992px) {
  .brand {
    display: none;
  }

  .social-buttons-float {
    position: fixed;
    width: 60px;
    bottom: 40px;
    right: 40px;
    z-index: 100;
    gap: 8px;
  }

}

.centered-text-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-service-img .motto {
  z-index: 3;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .container-service-img .motto {
    font-size: 1em;
  }
}


.container-service-img {
  background-color: #B2AFAB;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 30vh !important;
  z-index: 1;

  .filter::after {
    background-color: rgba(78, 142, 177, 0.5);
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
  }
}


.img-service {
  width: 100%
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
}

.brand-logo-tenloo {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  opacity: 0.9999;
}