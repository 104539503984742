.social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    gap: 8px;
}

.social-buttons-attorney {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    transform: translate(-5%, -140%);
    gap: 8px;
}

.social-buttons-float {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    z-index: 100;
    gap: 8px;
}

$social-button-size: 64px;
$social-button-icon-size: 0.4;
$social-button-background: #fff;
$social-button-active-color: #fff;
$social-button-transition-time: 0.3s;

$social-button-colors: (
    'callus': $primary-color,
    'mail': #0072c6,
    'facebook': #3b5999,
    'whatsapp': #25d366,
    'linkedin': #0077b5,
    'github': #6e5494,
    'codepen': #212121,
    'steam': #7da10e,
    'snapchat': #eec900,
    'twitter': #55acee,
    'instagram': #e4405f,
    'npmjs': #c12127,
);

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: $social-button-size;
    height: $social-button-size;
    text-decoration: none;
    border-radius: 100%;
    background: $social-button-background;
    text-align: center;
    box-shadow: 0px 0px 61px -10px rgba(78, 142, 177, 0.75);
    -webkit-box-shadow: 0px 0px 61px -10px rgba(78, 142, 177, 0.75);
    -moz-box-shadow: 0px 0px 61px -10px rgba(78, 142, 177, 0.75);

    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-radius: 100%;
        transition: $social-button-transition-time;
    }

    &:focus,
    &:hover {
        color: $social-button-active-color;

        &::after {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            margin-left: calc(-50% - 1px);
        }
    }

    i,
    svg {
        position: relative;
        z-index: 1;
        transition: $social-button-transition-time;
    }

    i {
        font-size: $social-button-size * $social-button-icon-size;
    }

    svg {
        height: percentage($social-button-icon-size);
        width: percentage($social-button-icon-size);
    }

    @each $name,
    $color in $social-button-colors {
        &--#{$name} {
            color: $color;

            &::after {
                background: $color;
            }
        }
    }
}