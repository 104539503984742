.section {
    padding: 70px 0;
    position: relative;
    background-color: #FFFFFF;
}

.section2 {
    padding: 0px 0;
    position: relative;
    background-color: #FFFFFF;
}

.section-with-space {
    padding: 60px 0;
}

.section-gray {
    background-color: #EEEEEE;
}

.section-nude {
    background-color: $bg-nude;
}

.section-gold {
    background-color: #caac90;
    color: $white-color;
}

.section-brown {
    background-color: #A59E94;
    color: $white-color;
}

.section-light-blue {
    background-color: $primary-color;
    color: $white-color;
}

.section-dark-blue {
    background-color: #506367;
    color: $white-color;
}

.section-dark {
    background-color: #0b1011;
}

.section-image,
.section-gold,
.section-dark {
    position: relative;

    .title,
    .info-title,
    .card-plain .card-title {
        color: $white-color !important;
    }

    .category,
    .description,
    .card-plain .card-description,
    .card-plain .card-category {
        color: rgba($white-color, .7);
    }

    hr {
        border-color: rgba(255, 255, 255, 0.19);
    }
}

.page-header,
.carousel-caption {

    .title,
    .info-title {
        color: $white-color;
    }
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
    padding: 80px 0;
}

.section-image {
    background-position: center center;
    background-size: cover;

    &:before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    & .container {
        position: relative;
        z-index: 2;
    }
}

.section-login {
    min-height: 700px;
}

.section-image-text {
    min-height: 1000px;
}

@media screen and (max-width: 600px) {

    .section-image-text{
        min-height: 40vh;
    }
}

.section-blur>* {
    filter: blur(2.5px);    
    //   -webkit-filter: blur(8px);
}
.section-not-blur {
    filter: blur(0);   
}

// #parent_div {
//     position: relative;
//     height: 100px;
//     width: 100px;
//   }

//   #background {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: red;
//     filter: blur(3px);
//     z-index: -1;
//   }

.section-image2 {
    background-position: center top;
    background-size: cover;

    &:before {
        // background-color: rgba(0, 0, 0, 0.5);
        background-color: rgba(78, 142, 177, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    & .container {
        position: relative;
        z-index: 2;
    }
}

// .section-image-text+im{
//     transform: translateY(50px);    
// }



@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */
    .page-header {
        .content-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }

        .presentation-title {
            background: none !important;
        }
    }
}


[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
    padding: 80px 0;
}

.section-image {
    background-position: center center;
    background-size: cover;
    min-height: 400px;
    // max-height: 40vh !important;

    &:before {
        // background-color: rgba(0, 0, 0, 0.5);
        background-color: rgba(78, 142, 177, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    & .container {
        position: relative;
        z-index: 2;
    }
}