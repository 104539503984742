.footer {
    background-attachment: fixed;
    position: relative;
    line-height: 20px
}

.footer .footer-nav ul {
    padding-left: 0;
    margin-bottom: 0
}

.footer nav>ul {
    list-style: none;
    font-weight: 400
}

.footer nav>ul>li {
    display: inline-block;
    padding: 10px 15px;
    margin: 15px 3px;
    line-height: 20px;
    text-align: center
}

.footer nav>ul a:not(.btn) {
    color: #bdbdbd ;
    display: block;
    margin-bottom: 3px;
    line-height: 1.6;
    opacity: .8;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600
}

.footer nav>ul a:not(.btn):focus,
.footer nav>ul a:not(.btn):hover {
    color: #fff;
    opacity: 1
}

.footer .copyright {
    color: #fff;
    padding: 10px 0;
    font-size: 14px;
    margin: 15px 3px;
    line-height: 20px;
    text-align: center
}

.footer .heart {
    color: #eb5e28
}

.footer {
    // background-color: #fff;
    background-color: #101218;
    line-height: 36px
}

.footer.footer-black h4 {
    color: #fff
}

.footer .links {
    display: inline-block
}

.footer .links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 600
}

.footer .links ul>li {
    display: inline-block;
    padding-right: 20px
}

.footer .links ul>li:last-child {
    padding-right: 0
}

.footer .links ul a:not(.btn) {
    color: #66615b;
    display: block;
    font-size: .9em;
    margin-bottom: 3px
}

.footer .links ul a:not(.btn):focus,
.footer .links ul a:not(.btn):hover {
    color: #403d39
}

.footer .links ul.uppercase-links {
    text-transform: uppercase
}

.footer .links ul.stacked-links {
    margin-top: 10px
}

.footer .links ul.stacked-links>li {
    display: block;
    line-height: 26px
}

.footer .links ul.stacked-links h4 {
    margin-top: 0
}

.footer .social-area {
    padding: 15px 0
}

.footer .social-area .btn {
    margin-bottom: 3px;
    padding: 8px
}

.footer .social-area .btn i {
    margin-right: 0 !important;
    color: #fff
}

.footer hr {
    border-color: #ddd;
    border-width: 1px 0 0;
    margin-top: 5px;
    margin-bottom: 5px
}

.footer .copyright {
    color: #bdbdbd ;
    font-size: .9em
}

.footer .copyright ul>li {
    padding-right: 0
}

.footer .title {
    color: #403d39
}